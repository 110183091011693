<template>
	<div>
		<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
			<template slot="scopedSlots" slot-scope="{ columnData }">
				<a @click="SowReason(columnData)">
					<a-statistic
						:precision="2"
						:value="
							columnData.discountInterestAmount +
							columnData.discountOtherAmount +
							columnData.discountPenaltyAmount +
							columnData.discountMaintenanceAmount +
							columnData.discountTrackingAmount
						"
					/>
				</a>
			</template>
			<template slot="action" slot-scope="{ columnData }">
				<a @click="Setting(columnData.id)" :disabled="columnData.status != 0">{{ $t('menus.financeMenu.repaymentReview') }}</a>
			</template>
		</search-list-table>
		<discount-modal ref="discountModal" :columnData="discountModalColumnData"></discount-modal>
	</div>
</template>
<script>
import DiscountModal from '@/views/businessComponents/DiscountModal.vue'
import { apiRepayCheck } from '@/api/loan'
import { apiBranchList } from '@/api/branch'
export default {
	data() {
		return {
			branchOfficeList: [],
			timer: null,
			searchValues: {},
			discountModalColumnData: {}
		}
	},

	components: { DiscountModal },
	computed: {
		formSearchList() {
			return [
				// {
				// 	label: 'table.applyInfo.cloums.caseId',
				// 	type: 'input',
				// 	validateField: ['caseId']
				// },
				{
					label: 'table.finance.cloum.payID',
					type: 'input',
					validateField: ['paymentId']
				},
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.applyInfo.cloums.mobile',
					type: 'input',
					validateField: ['mobile']
				},
				{
					label: 'table.finance.cloum.arrivalTime',
					type: 'range-picker',
					validateField: ['arrivalTime', { initialValue: [] }]
				},
				{
					label: 'table.finance.cloum.payType',
					type: 'select',
					validateField: ['transactionType'],
					selectOption: [0, 1, 2, 3, 4],
					prefix: 'table.finance.repayment.payTypeMap'
				},
				{
					label: 'table.applyInfo.loanApplyLabel.branchOffice',
					type: 'select',
					validateField: ['officeId'],
					selectOption: this.branchOfficeList,
					dataPreType: {
						value: 'id',
						text: 'storeName'
					}
				}
			]
		},
		columns() {
			return [
				{
					// 案件ID
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					width: 80,
					align: 'center',
					fixed: 'left'
				},
				{
					// 支付ID
					title: this.$t('table.finance.cloum.payID'),
					dataIndex: 'paymentId',
					align: 'center',
					fixed: 'left'
				},
				{
					// 案件ID
					title: this.$t('table.applyInfo.cloums.caseId'),
					dataIndex: 'loanId',
					align: 'center',
					customRender: (v, o) => {
						return o.casePrefix + '-' + o.caseId
					}
				},
				{
					// 用户名
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'fullName',
					width: 150,
					align: 'center'
				},
				{
					// 车牌号
					title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
					dataIndex: 'registrationNumbers',
					align: 'center',
					customRender: (v) => (v ? v.join('/') : '-')
				},
				{
					// 手机号
					title: this.$t('table.finance.cloum.mobile'),
					dataIndex: 'mobiles',
					align: 'center',
					width: 150,
					customRender: (v) => {
						let vhtml = v
							? v.map((item) => {
									return <p>{item}</p>
							  })
							: ''
						return vhtml
					}
				},
				// {
				// 	// 支付金额
				// 	title: this.$t('table.finance.cloum.payAmount'),
				// 	dataIndex: 'paymentAmount',
				// 	align: 'center',
				// 	width: 150,
				// 	scopedSlots: { customRender: 'money_format' }
				// },
				{
					// 到账时间
					title: this.$t('table.finance.cloum.arrivalTime'),
					dataIndex: 'transactionDate',
					align: 'center',
					width: 150
				},
				{
					// 支付凭证
					title: this.$t('table.finance.cloum.payCode'),
					align: 'center',
					width: 150,
					customRender: (o) => {
						return <a onclick={() => this.GoPayCode(o.transactionFiles)}>{this.$t('page.checkDetail')}</a>
					}
				},
				{
					// 还款类型
					title: this.$t('table.finance.cloum.payType'),
					dataIndex: 'transactionTypeEnum',
					align: 'center',
					width: 150,
					customRender: (v, o) => {
						return this.$t(`table.finance.repayment.payTypeMap.${v.value}`)
					}
				},
				{
					// 到账金额
					title: this.$t('table.finance.cloum.amountReceived'),
					dataIndex: 'amount',
					align: 'center',
					width: 150,
					scopedSlots: { customRender: 'money_format' }
				},
				{
					// 额外平账金额
					title: this.$t('table.finance.cloum.balance'),
					align: 'center',
					width: 150,
					scopedSlots: { customRender: 'scopedSlots' }
				},
				{
					// 是否结单
					title: this.$t('table.finance.cloum.check'),
					align: 'center',
					dataIndex: 'needClosedLoan',
					width: 150,
					customRender: (v, o) => {
						return o.needClosedLoan ? this.$t('page.yes') : this.$t('page.no')
					}
				},
				{
					// 录入人ID
					title: this.$t('table.finance.cloum.entryName'),
					dataIndex: 'userName',
					align: 'center',
					width: 150
				},
				{
					// 状态
					title: this.$t('table.finance.cloum.status'),
					align: 'center',
					dataIndex: 'status',
					width: 150,
					customRender: (v) => {
						return this.$t(`table.finance.cloum.statusMap.${v}`)
					}
				},
				{
					// 操作
					title: this.$t('operate.operate'),
					align: 'center',
					width: 150,
					fixed: 'right',
					scopedSlots: { customRender: 'action' }
				}
			]
		}
	},
	methods: {
		initDataSource(initParams) {
			return apiRepayCheck({ ...initParams, isRecorded: 1 })
		},
		// 查看原因
		SowReason(columnData) {
			this.$refs.discountModal.updateModalShow(true)
			this.discountModalColumnData = columnData
		},
		GoPayCode(url) {
			let linkurl = this.$Util.getObsImageUrl(this.$store.state.pageState.authObj, url)
			window.open(linkurl, '_blank')
		},
		// 还款审核
		Setting(id) {
			this.$router.push({
				path: 'reviewDetails',
				query: {
					id
				}
			})
		}
	},
	beforeDestroy() {
		clearTimeout(this.timer)
	},
	destroyed() {
		clearTimeout(this.timer)
	},
	created() {
		this.$nextTick(() => {
			apiBranchList().then((res) => {
				this.branchOfficeList = res
			})
		})
	}
}
</script>
<style scoped lang="less">
.ant-input,
.ant-input-number,
.ant-select {
	width: 200px;
}
.ant-form-item {
	margin-bottom: 10px;
}
.file {
	left: 0;
	top: 0;
	width: 100%;
	bottom: 0;
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
</style>
