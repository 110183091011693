<template>
	<a-modal class="discount-modal" v-drag-modal title="Discount" v-model="discountModalShow" :destroyOnClose="true" width="600px" :footer="null">
		<a-table :columns="discountModal.columns" :rowKey="(record, index) => index" :dataSource="discountModalDataSource" :pagination="false" bordered>
		</a-table>
		<p class="title"
			>Discount Reason : <span>{{ discountModalExtraReason }}</span></p
		>
	</a-modal>
</template>
<script>
export default {
	props: ['columnData'],
	data() {
		return {
			discountModalShow: false,
			discountModal: {
				columns: [
					{
						title: 'Discount',
						dataIndex: 'discountName',
						align: 'center'
					},
					{
						title: 'Discount Amount',
						dataIndex: 'discountAmount',
						align: 'center',
						customRender: (v, o) => {
							return <a-statistic precision={2} value={o.discountAmount === null ? ' - ' : o.discountAmount} />
						}
					}
				]
			}
		}
	},
	computed: {
		discountModalDataSource() {
			return [
				{
					discountName: 'Discount on Tracking Fee',
					discountAmount: this.columnData.discountTrackingAmount
				},
				{
					discountName: 'Discount on Loan Maintenance Fee',
					discountAmount: this.columnData.discountMaintenanceAmount
				},
				{
					discountName: 'Discount on Interest',
					discountAmount: this.columnData.discountInterestAmount
				},
				{
					discountName: 'Discount on Penalty',
					discountAmount: this.columnData.discountPenaltyAmount
				},
				{
					discountName: 'Discount on Other Fees',
					discountAmount: this.columnData.discountOtherAmount
				}
			]
		},
		discountModalExtraReason() {
			return this.columnData.extraReason
		}
	},
	methods: {
		updateModalShow(val) {
			this.discountModalShow = val
		}
	}
}
</script>
<style scoped lang="less">
.discount-modal {
	.title {
		font-weight: 600;
		margin: 20px 0;
		span {
			font-weight: normal;
		}
	}
}
</style>
